.swal2-container {
    z-index: 1500;
}
.swal2-container .swal2-popup {
    padding: 32px;
    border-radius: 8px;
}
.swal2-container .swal2-html-container {
    margin: 0;
}

@media all and (max-width: 499.98px) {
    .swal2-container .swal2-popup {
        padding: 16px;
    }
}
